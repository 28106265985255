import { Dispatch, FormEventHandler, ReactNode, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';

import { ElementSpyInstance } from '@hh.ru/browser-api-utils';
import { ButtonSize, InputSize } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonAppearance, ButtonKind, ButtonType } from 'bloko/blocks/button';
import { InputType } from 'bloko/blocks/inputText';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import Button from 'src/components/MagritteRedesignComponents/Button';
import Input from 'src/components/MagritteRedesignComponents/Input';
import CodeError from 'src/components/OTP/CodeError';
import CodeSender, { AvailableOtpUrls } from 'src/components/OTP/CodeSender';
import OtpByCall from 'src/components/OTP/OtpByCall';
import translation from 'src/components/translation';
import useAutofocus from 'src/hooks/useAutofocus';
import { Verification as VerificationType } from 'src/models/applicant/auth';

const MIN_CODE_LENGTH = 4;

const TrlKeys = {
    firstName: 'newApplicant.firstName',
    lastName: 'newApplicant.lastName',
    send: 'account.connect.merge_by_code.send',
    codePlaceholder: 'account.connect.merge_by_code.codePlaceholder',
    confirmCode: 'account.connect.merge_by_code.confirm',
    submitSignup: 'signupForm.create.submit',
};

interface RenderInputArgs {
    placeholder?: string;
    isAutofocus?: boolean;
    size?: InputSize;
}

interface RenderSubmitArgs {
    isStretched?: boolean;
    size?: ButtonSize;
}

interface RenderCancelButtonArgs {
    isStretched?: boolean;
    onClick: () => void;
    trl: string;
}

export interface VerificationFormRenderProps {
    renderInput: (args?: RenderInputArgs) => ReactNode;
    renderCodeError: () => ReactNode;
    renderCodeSender: () => ReactNode;
    renderSubmit: (args?: RenderSubmitArgs) => ReactNode;
    renderCancelButton: (args: RenderCancelButtonArgs) => ReactNode;
    hasVerificationError?: string;
}

interface VerificationFormProps {
    isSignupPage?: boolean;
    login?: string;
    authType?: string;
    operationType?: string;
    onShownAnalytics?: (ref: RefObject<HTMLFormElement>) => undefined | ElementSpyInstance;
    onSubmit: (
        setSending: Dispatch<SetStateAction<boolean>>,
        setVerification: Dispatch<SetStateAction<VerificationType | null>>,
        code: string
    ) => void;
    url?: AvailableOtpUrls;
    render: (renderProps: VerificationFormRenderProps) => ReactNode;
}

export type VerificationFormBaseProps = Omit<VerificationFormProps, 'render'>;

const Verification: TranslatedComponent<VerificationFormProps> = ({
    trls,
    render,
    login,
    authType,
    isSignupPage,
    onShownAnalytics,
    operationType,
    onSubmit,
    url = '/account/otp_generate' as const,
}) => {
    const [code, setCode] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [verification, setVerification] = useState<VerificationType | null>(null);
    const elementRef = useRef<HTMLFormElement>(null);
    const autofocusRef = useAutofocus();

    useEffect(() => {
        let elementSpyInstance: ElementSpyInstance | undefined;
        if (onShownAnalytics && elementRef.current) {
            elementSpyInstance = onShownAnalytics(elementRef);
        }
        return () => {
            elementSpyInstance?.stopSpying();
        };
    }, [elementRef, onShownAnalytics]);

    const confirmCode: FormEventHandler<HTMLFormElement> = (event) => {
        event?.preventDefault();
        onSubmit(setIsSending, setVerification, code);
    };

    const updateCode = (value: string) => {
        setVerification(null);
        setCode(value);
    };

    const renderInput = ({ placeholder, isAutofocus, size = 'large' }: RenderInputArgs = {}) => {
        return (
            <Input
                placeholder={placeholder || trls[TrlKeys.codePlaceholder]}
                data-qa="otp-code-input"
                name="otp-code-input"
                onChange={updateCode}
                invalid={!!verification}
                autoFocus
                ref={isAutofocus ? autofocusRef : undefined}
                uncontrolled
                size={size}
                blokoComponentProps={{
                    type: InputType.Number,
                }}
            />
        );
    };

    const renderCodeError = () => {
        return <CodeError verification={verification} authType={authType} />;
    };

    const renderCodeSender = () => {
        return (
            <CodeSender
                login={login}
                isSignupPage={isSignupPage}
                url={url}
                operationType={operationType}
                otpType={authType === 'EMAIL' ? 'email' : 'phone'}
                recaptchaClassName="account-login-recaptcha"
            />
        );
    };

    const renderSubmit = ({ isStretched, size = 'large' }: RenderSubmitArgs = {}) => {
        return (
            <Button
                mode="primary"
                size={size}
                style="accent"
                type="submit"
                blokoComponentProps={{
                    kind: ButtonKind.Primary,
                    type: ButtonType.Submit,
                }}
                disabled={code.length < MIN_CODE_LENGTH || isSending}
                data-qa="otp-code-submit"
                stretched={isStretched}
            >
                {trls[TrlKeys.confirmCode]}
            </Button>
        );
    };

    const renderCancelButton = ({ isStretched, onClick, trl }: RenderCancelButtonArgs) => {
        return (
            <BlokoButton
                kind={ButtonKind.Primary}
                appearance={ButtonAppearance.Outlined}
                type={ButtonType.Button}
                onClick={onClick}
                data-qa={'otp-code-cancel'}
                stretched={isStretched}
            >
                {trl}
            </BlokoButton>
        );
    };

    const hasVerificationError = verification?.key;

    return (
        <Form data-qa={'otp-code-form'} onSubmit={confirmCode} ref={elementRef}>
            {render({
                renderInput,
                renderCodeError,
                renderCodeSender,
                renderSubmit,
                renderCancelButton,
                hasVerificationError,
            })}
            {authType === 'PHONE_CALL' && <OtpByCall login={login} />}
        </Form>
    );
};

export default translation(Verification);
