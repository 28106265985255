import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { usePush } from '@hh.ru/redux-spa-middleware';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import DefaultVerification from 'src/components/Verification/Verification';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { VR_OPERATION_TYPE } from 'src/models/vrSignupLogin';
import formatAnalyticErrors from 'src/utils/analytics/formatAnalyticErrors';
import fetcher from 'src/utils/fetcher';
import { handleVrSuccessAuthorizationUser } from 'src/utils/vr/vrSignupLoginUtils';

import {
    loginBy,
    getVerificationFormStepOperationType,
} from 'src/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';

const VerificationFormStep = ({ Verification = DefaultVerification, isSignupPage, next, ...props }) => {
    const { addNotification } = useNotification();
    const { login, authType, backurl, accountType, isMultiAccount } = useSelector(({ otp }) => otp);
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const { vrResponseHash, query, surveyType } = useSelector(({ vrSignupLogin }) => vrSignupLogin);
    const analyticsParams = useSelector(({ analyticsParams }) => analyticsParams);
    const operationType = getVerificationFormStepOperationType({ accountType, isVrFlow: !!vrResponseHash });
    const push = usePush();

    const onShownAnalytics = useCallback(
        (elementRef) => {
            let elementSpyInstance;
            if (elementRef.current) {
                elementSpyInstance = Analytics.sendHHEventElementShown(elementRef.current, {
                    elementName: 'code_confirmation',
                    vacancyId,
                    accountType,
                    isMultiAccount,
                    authType: loginBy[authType] || 'unknown',
                    isSignupPage,
                });
            }
            return elementSpyInstance;
        },
        [accountType, authType, isMultiAccount, isSignupPage, vacancyId]
    );

    const onSubmitAnalytics = (errorCode) => {
        Analytics.sendHHEvent('form_submit', {
            formName: 'code_confirmation',
            vacancyId,
            authType: loginBy[authType] || 'unknown',
            accountType,
            isMultiAccount,
            errors: errorCode ? formatAnalyticErrors('code_verification', errorCode) : null,
            isSignupPage,
        });
    };

    const onSubmit = async (setIsSending, setVerification, code) => {
        let response;
        setIsSending(true);

        try {
            response = await fetcher.postFormData('/account/login/by_code', {
                username: login,
                code,
                remember: true,
                accountType: isSignupPage ? 'APPLICANT' : undefined,
                isApplicantSignup: isSignupPage,
                operationType: vrResponseHash ? VR_OPERATION_TYPE : 'otp_auth',
                backurl,
            });
        } catch (error) {
            setIsSending(false);
            const errorCode = error?.response?.data?.error?.key;
            const isMultiAccountCreation = error?.response?.data?.isMultiAccountCreation;
            const operationType = error?.response?.data?.operationType;

            if (errorCode === 'EMPLOYER_NOT_ALLOWED' && isMultiAccountCreation) {
                try {
                    await fetcher.post('/multiaccount_grouping/reset_employer_password', {
                        code,
                        operationType: operationType || 'OTP_GENERATE',
                    });
                } catch {
                    onSubmitAnalytics(errorCode);
                    push(error.response.data.employerLoginURL);
                    return;
                }

                onSubmitAnalytics(errorCode);
                next(code, isMultiAccountCreation);
                return;
            }

            if (errorCode === 'EMPLOYER_NOT_ALLOWED' || errorCode === 'UNEXPECTED_USER_TYPE') {
                push(error.response.data.employerLoginURL);
                onSubmitAnalytics(errorCode);
                return;
            }

            if (errorCode === 'UNEXPECTED_USER_TYPE' || errorCode === 'ANONYMOUS_ONLY') {
                push(error.response.data.loginURL);
                onSubmitAnalytics(errorCode);
                return;
            }

            if (errorCode === 'ACCOUNT_NOT_FOUND') {
                onSubmitAnalytics();
                next(code, isMultiAccountCreation);
                return;
            }

            if (errorCode === 'ACCOUNT_BLOCKED') {
                setVerification({ key: errorCode, success: false });
                onSubmitAnalytics(errorCode);
                return;
            }

            onSubmitAnalytics('SERVER_ERROR');
            defaultRequestErrorHandler(error, addNotification);
            return;
        }

        const errorCode = response?.data?.success ? null : response?.data?.verification?.key;
        onSubmitAnalytics(errorCode);
        setIsSending(false);

        if (response?.data?.success) {
            if (vrResponseHash) {
                handleVrSuccessAuthorizationUser(surveyType, query, analyticsParams);
                return;
            }
            window.location.assign(backurl);
            return;
        }

        setVerification(response?.data?.verification);
    };

    return (
        <Verification
            onShownAnalytics={onShownAnalytics}
            authType={authType}
            login={login}
            onSubmit={onSubmit}
            operationType={operationType}
            isSignupPage={isSignupPage}
            {...props}
        />
    );
};

export default translation(VerificationFormStep);
