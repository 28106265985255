import { useEffect, useRef, useState } from 'react';
import { Field as FinalField, Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { useCaptcha } from '@hh.ru/hhcaptcha';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { ButtonScale } from 'bloko/blocks/button';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import Control from 'src/components/ApplicantSignInSignUpFlow/Control';
import Submit from 'src/components/ApplicantSignInSignUpFlow/Submit';
import VKAppListener from 'src/components/ApplicantSignInSignUpFlow/VKAppListener';
import Form from 'src/components/Form';
import Hhcaptcha from 'src/components/Hhcaptcha';
import { useNotification } from 'src/components/Notifications/Provider';
import Recaptcha from 'src/components/Recaptcha';
import translation from 'src/components/translation';
import useApplicantSignupFields from 'src/hooks/useApplicantSignupFields';
import useAutofocus from 'src/hooks/useAutofocus';
import useInitialFields from 'src/hooks/useInitialFormFields';
import usePostponedVacancy from 'src/hooks/usePostponedVacancy';
import { useSelector } from 'src/hooks/useSelector';
import useVkAppIntegrationFocus from 'src/hooks/useVkAppIntegrationFocus';
import { SIGNUP_FORM } from 'src/hooks/useVkAppIntegrationReady';
import { OtpOperationType } from 'src/models/otp';
import { VR_OPERATION_TYPE } from 'src/models/vrSignupLogin';
import formatAnalyticErrors from 'src/utils/analytics/formatAnalyticErrors';
import fetcher from 'src/utils/fetcher';

const otpAction = makeSetStoreField('otp');
const formFields = ['login'];

export const getFieldProps = ({ name, conditions }, { value, error } = {}) => ({
    name,
    maxLength: conditions.parts[0].maxLength,
    value,
    error,
});

export const loginBy = { EMAIL: 'email', SMS: 'phone' };

const requiredValidator = (value) => (value?.trim?.() ? undefined : 'EMPTY');

export const getVerificationFormStepOperationType = ({ accountType, isVrFlow }) => {
    if (isVrFlow) {
        return VR_OPERATION_TYPE;
    }
    return accountType === 'EMPLOYER' ? OtpOperationType.EmployerOtpAuth : OtpOperationType.ApplicantOtpAuth;
};

const submitAnalytics = ({ errors, authType = null, vacancyId, isSignupPage, accountType, inputText }) => {
    Analytics.sendHHEvent('form_submit', {
        formName: 'applicant_authorization',
        authType: loginBy[authType] || 'unknown',
        errors: formatAnalyticErrors('login', errors),
        type: 'by_code',
        vacancyId,
        isSignupPage,
        accountType,
        inputText,
    });
};

const focusAnalytics = ({ vacancyId }) => {
    Analytics.sendHHEvent('form_field_touch', {
        formName: 'applicant_authorization',
        fieldName: 'login',
        type: 'by_code',
        vacancyId,
        authType: 'unknown',
    });
};
/**
 *
 * @param {string} inputValue - данные введенные пользователем в инпут логина
 * @returns {string} - строка, в которой для имейла маскировано все, кроме домена
 * для телефона - все, кроме первых 6 символов. В остальных случая маскируем все, кроме пробелов
 */
const getMaskedInputValue = (inputValue) => {
    if (!inputValue) {
        return '';
    }
    const trimmedValue = inputValue.replaceAll(/ /gi, '');
    if (trimmedValue.includes('@')) {
        const splittedEmail = trimmedValue.split('@');
        const maskedPart = splittedEmail[0].replaceAll(/./gi, '*');
        return [maskedPart, splittedEmail.pop()].join('@');
    }
    if (/^[\d\-+()]+$/.test(trimmedValue)) {
        return trimmedValue
            .replaceAll(/ /gi, '')
            .split('')
            .map((char, index) => (index > 6 ? '*' : char))
            .join('');
    }

    return trimmedValue.replace(/[^ ]/g, '*');
};

const SignInForm = ({
    backurl,
    next,
    render,
    trls,
    callbackSubmit,
    isSignupPage,
    isAutofocusEnabled = true,
    dataQaModifier,
}) => {
    const [isFetching, setFetching] = useState(false);
    const [fieldsSignup, setFieldsSignup] = useState({});
    const formElement = useRef(null);
    const initialFields = useInitialFields(formElement, formFields);
    const dispatch = useDispatch();
    const currentVacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const { postponedVacancy, postponedVacancyInFavorites } = usePostponedVacancy();
    const vacancyId = currentVacancyId || (postponedVacancyInFavorites ? null : postponedVacancy?.vacancyId);
    const favoriteVacancyId = postponedVacancyInFavorites ? postponedVacancy?.vacancyId : null;
    const enteredLogonValue = useSelector(({ authUrl }) => authUrl['login-field-value-entered']);
    const lastLogonValue = useSelector(({ authUrl }) => authUrl['login-field-value'] || '');
    const metaRole = useSelector(({ metaRole }) => metaRole);
    const { prefilledLogin: urlLogin } = useSelector(({ router }) => router.location.query);
    const analyticsVacancyId = vacancyId ?? favoriteVacancyId;
    const { addNotification } = useNotification();
    const push = usePush();

    const autofocusRef = useAutofocus();
    const focusHandle = useVkAppIntegrationFocus(SIGNUP_FORM);
    const fields = useApplicantSignupFields();
    const captcha = useCaptcha();

    const login = isSignupPage ? urlLogin || null : enteredLogonValue || lastLogonValue;

    const handleFocus = (event) => {
        focusHandle(event);
        focusAnalytics({
            vacancyId: analyticsVacancyId,
        });
    };

    useEffect(() => {
        formElement.current &&
            Analytics.sendHHEventElementShown(formElement.current, {
                name: 'applicant_authorization',
                type: 'by_code',
                isSignupPage,
                vacancyId: analyticsVacancyId,
            });
    }, [isSignupPage, analyticsVacancyId]);

    if (fields === null) {
        return null;
    }

    const submitForm = async () => {
        const formData = new FormData(formElement.current);
        const maskedInputValue = getMaskedInputValue(formData.get('login'));
        setFetching(true);
        let data;
        try {
            const formData = new FormData(formElement.current);
            captcha.addCaptchaParams(formData);
            const response = await fetcher.post('/account/otp_generate', formData);
            data = response.data;
        } catch (error) {
            const handlerResult = defaultRequestErrorHandler(error, addNotification);
            setFetching(false);
            const err = error.response ? error.response : handlerResult;
            callbackSubmit?.(error);
            submitAnalytics({ errors: err, isSignupPage, vacancyId: analyticsVacancyId, inputText: maskedInputValue });
            return;
        }

        const errorKey = data.success ? null : data.key;
        const errorKind = data.recaptcha?.isBot ? 'RECAPTCHA_IS_BOT' : errorKey;

        if (errorKey === 'EMPLOYER_ACCOUNT_LOGIN' || errorKey === 'PASSWORD_REQUIRED') {
            submitAnalytics({
                errors: errorKind,
                authType: data.otp?.authType,
                accountType: data.accountType,
                isSignupPage,
                vacancyId: analyticsVacancyId,
                inputText: maskedInputValue,
            });
            push(data.redirectURL);
            return;
        }

        setFetching(false);
        callbackSubmit?.(!!errorKey && errorKey !== 'CODE_SEND_BLOCKED');
        submitAnalytics({
            errors: errorKind,
            authType: data.otp?.authType,
            accountType: data.accountType,
            isSignupPage,
            vacancyId: analyticsVacancyId,
            inputText: maskedInputValue,
        });

        captcha.updateCaptcha(data);

        if (data.otp) {
            dispatch(otpAction(data.otp));
        }

        if (data.redirectURL) {
            window.location.assign(data.redirectURL);
        }

        if (data.key === '2FA_REQUIRED') {
            return;
        }

        if (data.success || data.key === 'CODE_SEND_BLOCKED') {
            next();
        } else {
            setFieldsSignup({ ...fieldsSignup, login: { ...fieldsSignup.login, error: data.key } });
        }
    };

    const renderLogin = ({ validate, placeholder, size } = {}) => (
        <FinalField
            component={Control}
            onFocus={handleFocus}
            placeholder={placeholder ?? trls[SignInForm.trls.login]}
            data-qa="account-signup-email"
            inputMode="email"
            validate={validate || requiredValidator}
            inputRef={(isAutofocusEnabled && autofocusRef) || undefined}
            size={size}
            {...getFieldProps(fields.login, fieldsSignup.login)}
        />
    );

    const renderCaptcha = () => (
        <>
            <Recaptcha className="account-login-recaptcha" />
            <Hhcaptcha size="large" />
        </>
    );

    const renderSubmitButton = (trl, additionalProps = {}) => (
        <Submit text={trl} isFetching={isFetching} scale={ButtonScale.Large} {...additionalProps} />
    );

    return (
        <FinalForm
            onSubmit={submitForm}
            initialValues={{
                login: initialFields.login || login,
                vacancyId,
                favoriteVacancyId,
            }}
            render={({ handleSubmit, form }) => (
                <VKAppListener form={form} fields={formFields}>
                    <Form
                        data-qa={classnames('account-signup', { [dataQaModifier]: dataQaModifier })}
                        onSubmit={handleSubmit}
                        ref={formElement}
                        method="post"
                        action="/account/otp_generate"
                    >
                        <input type="hidden" name="backurl" value={backurl} />
                        <input type="hidden" name="isSignupPage" value={isSignupPage} />
                        {!isSignupPage && <input type="hidden" name="operationType" value="applicant_otp_auth" />}
                        {metaRole && <input type="hidden" name="role" value={metaRole} />}
                        {vacancyId && <input type="hidden" name="vacancyId" value={vacancyId} />}
                        {favoriteVacancyId && (
                            <input type="hidden" name="favoriteVacancyId" value={favoriteVacancyId} />
                        )}
                        {render({
                            renderLogin,
                            renderSubmitButton,
                            renderCaptcha,
                            isFetching,
                        })}
                    </Form>
                </VKAppListener>
            )}
        />
    );
};

SignInForm.trls = {
    login: 'login.placeholder',
};

export default translation(SignInForm);
